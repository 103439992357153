const EndPoints = {
  auth: {
    LOGIN: "/user/login",
    LOGOUT: "/user/logout",
  },
  user: {
    EVENT_DATA: "/events/getStock",
    IS_READ:"/user/isread",
    GET_PROFILE: "/user/profile",
    GET_MACROS: "/user/macros",
    UPDATE_USER_PROFILE: "/user/profile/update",
    MANAGE_EVENT: "events/getCusto",
    UPDATE_EVENT: "events/updateCustom",
    PROFILE_PROGESS: "/user/profile/progress",
    DELETE_PROFILE_PROGRESS: "/user/profile/progress/delete",
    UPDATE_NOTIFICATION_LEVEL: "/user/profile/update/notificationlevel",
    SEND_FCM_TOKEN: "/save-fcm-token",
    FILL_DATA: "/leptin/fill",
    SUN_EXP: "/sunexposure/new",
    POST_SOCIAL_LOGIN: "user/login-social-media"
  },
  event: {
    MANAGE_EVENT: "events/getCusto",
    UPDATE_EVENT: "events/updateCustom",
    DELETE_EVENTS: "events/deleteCustom",
    EVENT_LIST_BY_ID: "/events/:id/history",
    ADD_NEW_EVENTS: "/events/new",
    MARK_EVENT_COMPLETED: "/events/put",
  },
  lab: {
    ADD_NEW_LABS: "/labs/new",
    UPDATE_LABS: "/labs/update",
    DELETE_LAB: "/labs/delete",
    GET_LABS_HISTORY: "/labs/getHistory/:pageNo",
  },
  subjective: {
    ADD_NEW_SUBJECTIVE: "/subjective/new",
    UPDATE_SUBJECTIVE: "/subjective/update",
    DELETE_SUBJECTIVE: "/subjective/delete",
    GET_SUBJECTIVE_HISTORY: "/subjective/getHistory/:pageNo",
  },
  meals: {
    GET_MEALS_HISTORY: "/food/getHistory/:pageNo",
    DELETE_MEAL_HISTORY: "food/delete",
    DELETE_SEARCH_MEAL: "/food/deleterecipe",
    SEARCH_MEALS: "/food/search/:query",
    ADD_NEW_MEALS: "/food/newmeal",
  },
  activity: {
    NEW_ACTIVITY: "/activity/new",
    GET_ACTIVITY_HISTORY: "activity/getHistory/:page",
    ACTIVITY_UPDATE: "/activity/update",
    DELETE_ACTIVITY_HISTORY: "/activity/delete",
  },
  measurements: {
    NEW_MEASUREMENTS: "/measurement/new",
    MEASUREMENTS_HISTORY: "measurement/getHistory/:page",
    UPDATE_MEASUREMENTS: "/measurement/update",
    DELETE_MEASUREMENTS: "measurement/delete",
  },
  recipe: {
    CUSTOM_RECIPE: "/food/createCustomMeal",
    NEW_RECIPE: "food/checkRecipeAgain",
    GET_RECIPE_HISTORY: "/food/getRecipeHistory/:page",
    DELETE_RECIPE_HISTORY: "/food/deleteRecipeHistory",
  },
  notification: {
    GET_NOTIFICATION: "/notifications/getnotification/:offset",
    DELETE_NOTIFICATION: "/notifications/delete",
  },
};

export default EndPoints;
